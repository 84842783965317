import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Modal } from "react-bootstrap";

class Tags extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      tags: props.tags,
      showAddTag: false,
      showNewTag: false,
      allTags: [],
      newTagName: "",
      errors: [],
    };
  }

  updateProductTags = () => {
    const { organization, productToken } = this.props;
    axios.get(`/accounts/${organization.token}/product_tags?product_token=${productToken}`).then((response) => {
      this.setState({
        tags: response.data,
        showAddTag: false,
      });
    });
  };

  addTag = (tag) => {
    const data = {
      product_tag: {
        product_token: this.props.productToken,
        tag_id: tag.id,
      },
    };

    this.setState((prevState) => ({
      tags: [...prevState.tags, tag],
      showAddTag: false,
    }));

    axios
      .post(`/accounts/${this.props.organization.token}/product_tags`, data)
      .then(() => {
        // Success. Do nothing. State already up to date
      })
      .catch(() => {
        // Actually something went wrong. Refresh the list of tags. Wait a sec first.
        setTimeout(this.updateProductTags, 1000);
      });

    this.mixpanelAddTag(tag);
  };

  removeTag = (tag) => {
    const data = {
      product_tag: {
        product_token: this.props.productToken,
        tag_id: tag.id,
      },
    };

    this.setState((prevState) => ({
      tags: _.filter(prevState.tags, (t) => t.id !== tag.id),
    }));

    axios
      .delete(`/accounts/${this.props.organization.token}/product_tags/${tag.id}`, { data: data })
      .then(() => {
        // Success. Do nothing. State already up to date
      })
      .catch(() => {
        // Actually something went wrong. Refresh the list of tags. Wait a sec first.
        setTimeout(this.updateProductTags, 1000);
      });

    this.mixpanelRemoveTag(tag);
  };

  handleAddTag = () => {
    this.setState({ showAddTag: true });
  };

  handleAddTagHide = (e) => {
    e.preventDefault();
    this.setState({ showAddTag: false });
  };

  getTags = () => {
    axios.get(`/accounts/${this.props.organization.token}/tags.json`).then((response) => {
      this.setState({ allTags: response.data });
    });
  };

  handleOpenCreate = (e) => {
    e.preventDefault();
    this.setState({ showAddTag: false, showNewTag: true });
  };

  handleNewTagHide = (e) => {
    e.preventDefault();
    this.setState({
      showAddTag: false,
      showNewTag: false,
      newTagName: "",
      errors: [],
    });
  };

  createNewTag = () => {
    const { newTagName } = this.state;
    this.setState({ errors: [] });

    axios
      .post(`/accounts/${this.props.organization.token}/tags/create_and_tag`, {
        name: newTagName,
        product_token: this.props.productToken,
      })
      .then((response) => {
        this.setState((prevState) => ({
          tags: [...prevState.tags, response.data],
          showAddTag: false,
          showNewTag: false,
          newTagName: "",
        }));
        this.mixpanelCreateTag(newTagName);
      })
      .catch((error) => {
        const errors = error.response.data;
        this.setState({ errors: errors });
      });
  };

  handleNewTagNameChange = (e) => {
    this.setState({ newTagName: e.target.value });
  };

  handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      this.createNewTag();
    }
  };

  mixpanelCreateTag(tagName) {
    const { productToken } = this.props;
    const { token, name } = this.props.organization;
    mixpanel.track("Product - Create Tag", {
      "Organization Token": token,
      "Organization Name": name,
      "Product Token": productToken,
      "Tag Name": tagName,
    });
  }

  mixpanelRemoveTag(tag) {
    const { productToken } = this.props;
    const { token, name } = this.props.organization;
    mixpanel.track("Product - Remove Tag", {
      "Organization Token": token,
      "Organization Name": name,
      "Product Token": productToken,
      Tag: tag,
    });
  }

  mixpanelAddTag(tag) {
    const { productToken } = this.props;
    const { token, name } = this.props.organization;
    mixpanel.track("Product - Add Tag", {
      "Organization Token": token,
      "Organization Name": name,
      "Product Token": productToken,
      Tag: tag,
    });
  }

  render() {
    const { organization, canUpdate } = this.props;
    const { tags, allTags, showAddTag, showNewTag, newTagName, errors } = this.state;

    const currentTag = tags.map((tag) => tag.id);
    const otherTags = _.filter(allTags, (tag) => !_.includes(currentTag, tag.id));

    return (
      <div className="tags-container">
        {tags.map((tag) => (
          <Tag key={tag.id} tag={tag} onClose={this.removeTag} canUpdate={canUpdate} />
        ))}
        {canUpdate && (
          <span className="add-tag" onClick={this.handleAddTag}>
            Add Tag
            <span className="icon">+</span>
          </span>
        )}

        <Modal
          className="product-tags__add-modal"
          show={showAddTag}
          onHide={this.handleAddTagHide}
          backdrop="static"
          onEnter={this.getTags}
        >
          <Modal.Header closeButton>Add Tag</Modal.Header>
          <Modal.Body className="p-0">
            <div className="product-tags__options-container">
              {otherTags.length > 0 ? (
                otherTags.map((tag) => (
                  <button className="product-tags__add-option" key={tag.id} onClick={() => this.addTag(tag)}>
                    {tag.name}
                  </button>
                ))
              ) : (
                <div className="product-tags__no-tags">
                  No available tags.&nbsp;
                  <a href="#" onClick={this.handleOpenCreate}>
                    Create a new one
                  </a>
                  !
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between -mx-4">
              <div className="button-group">
                <a className="button secondary" href={`/accounts/${organization.token}/tags`}>
                  Manage Tags
                </a>
              </div>
              <div className="button-group">
                <a className="button bordered secondary" href="#" onClick={this.handleAddTagHide}>
                  Cancel
                </a>
                <a className="button" href="#" onClick={this.handleOpenCreate}>
                  Create New Tag
                </a>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={showNewTag} onHide={this.handleNewTagHide}>
          <Modal.Header closeButton>New tag</Modal.Header>
          <Modal.Body>
            {errors.length > 0 && (
              <div className="mt-0 alert alert-critical product-tags___create-tag-error">
                {errors.map((message) => (
                  <div>{message}</div>
                ))}
              </div>
            )}

            <div className="form-control">
              <input
                type="text"
                className="form-control"
                placeholder="New tag name"
                autoFocus
                value={newTagName}
                onChange={this.handleNewTagNameChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <a href="#" className="modal-footer__cancel button bordered secondary" onClick={this.handleNewTagHide}>
                Cancel
              </a>
              <button className="button" onClick={this.createNewTag}>
                Create
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

class Tag extends React.Component {
  onClose = () => {
    this.props.onClose(this.props.tag);
  };

  render() {
    const { canUpdate } = this.props;
    const { name } = this.props.tag;

    return (
      <span className="tag">
        <span className="text">{name}</span>
        {canUpdate && (
          <button onClick={this.onClose} className="tag-close" title="Remove Tag">
            ⨯
          </button>
        )}
      </span>
    );
  }
}

Tags.propTypes = {
  organization: PropTypes.object,
  productToken: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  canUpdate: PropTypes.bool,
};

export default Tags;
