// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import "./src/config/axios-config";

import React from "react";
import ReactDOM from "react-dom";
import Tags from "./src/products/tags";
import TagsIndex from "./src/tags/tags-index";
import TagsShow from "./src/tags/tags-show";
import PersonalizationSettings from "./src/users/personalization-settings";

document.addEventListener("DOMContentLoaded", () => {
  const $productTags = $("#product-tags");

  if ($productTags.length > 0) {
    const tags = JSON.parse($productTags.attr("data-tags"));
    const productToken = $productTags.attr("data-product-token");
    const organization = JSON.parse($productTags.attr("data-organization"));
    const canUpdate = $productTags.attr("data-can-update") === "true";

    ReactDOM.render(
      <Tags organization={organization} productToken={productToken} tags={tags} canUpdate={canUpdate} />,
      $productTags[0],
    );
  }

  const $tagsIndex = $("#tags-index");
  if ($tagsIndex.length > 0) {
    const organization = JSON.parse($tagsIndex.attr("data-organization"));
    const canUpdate = JSON.parse($tagsIndex.attr("data-can-update"));
    ReactDOM.render(<TagsIndex organization={organization} canUpdate={canUpdate} />, $tagsIndex[0]);
  }

  const $tagsShow = $("#tags-show");
  if ($tagsShow.length > 0) {
    const organization = JSON.parse($tagsShow.attr("data-organization"));
    const tag = JSON.parse($tagsShow.attr("data-tag"));
    const canUpdate = JSON.parse($tagsShow.attr("data-can-update"));
    ReactDOM.render(<TagsShow organization={organization} tag={tag} canUpdate={canUpdate} />, $tagsShow[0]);
  }

  const $personalizationSettings = $("#user-personalization-settings");
  if ($personalizationSettings.length > 0) {
    const user = JSON.parse($personalizationSettings.attr("data-user"));
    ReactDOM.render(<PersonalizationSettings user={user} />, $personalizationSettings[0]);
  }
});
