// A class to hold some of the table definitions and helper methods related to
// defining a data table.
// columns is the array you'd pass to 'columns' in the dataTable definition
class DataTableDefinition {
  constructor(options) {
    this.columns = options.columns;
    this.defaultSortByColumns = options.defaultSortByColumns;
    this.defaultSortByDirections = options.defaultSortByDirections;
  }

  // NOTE:
  // https://datatables.net/forums/discussion/comment/76667/#Comment_76667
  // Can't use 'name' field to set starting order. Only index. Lame. So use
  // so use this method to get the column index from the column name.
  columnIndex(name) {
    return _.findIndex(this.columns, ['name', name]);
  }

  columnIndexes(names) {
    const self = this;
    return _.map(names, function (name) {
      return self.columnIndex(name);
    });
  }

  // Order is either set to the default (defined in the constructor) or passed in
  // via query parameters
  order() {
    const params = DataTableDefinition.getQueryParams();
    const self = this;

    if (_.isNil(params['sortBy'])) {
      params['sortBy'] = this.columnIndexes(this.defaultSortByColumns);
    } else {
      const columnNumbersAsStrings = decodeURIComponent(params['sortBy']).split(',');
      params['sortBy'] = _.map(columnNumbersAsStrings, function (columnName) {
        return self.columnIndex(columnName);
      });
    }

    if (_.isNil(params['direction'])) {
      params['direction'] = this.defaultSortByDirections;
    } else {
      params['direction'] = decodeURIComponent(params['direction']).split(',');
    }

    // convert from {sortBy: [14,15], direction: [asc,desc]} notation to
    // [[14,asc],[15,desc]]
    const finalForm = [];
    for (let i = 0; i < params['sortBy'].length; i++) {
      finalForm.push([params['sortBy'][i], params['direction'][i]]);
    }

    return finalForm;
  }
}

// class method
DataTableDefinition.getQueryParams = function () {
  let vars = [],
    hash;
  const hashes = window.location.search.substring(1).split('&');
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
};

export default DataTableDefinition;
