// Issue might occur where getRegion / getCurrency might be called before
// $organizationData is set...
let $organizationData;

$(function () {
  $organizationData = $('#organization-data');
});

function getRegion() {
  return $organizationData.attr('data-region');
}

function getCurrency() {
  return $organizationData.attr('data-currency');
}

function getCurrencySymbol() {
  return $organizationData.attr('data-currency-symbol');
}

function getCurrencyNamePlural() {
  return $organizationData.attr('data-currency-name-plural');
}

export default {
  getRegion,
  getCurrency,
  getCurrencySymbol,
  getCurrencyNamePlural,
};
