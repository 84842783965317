import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";

class TagsIndex extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tags: null,
      newTagName: "",
      showNewTag: false,
      errors: [],
    };
  }

  componentDidMount() {
    const { token } = this.props.organization;
    axios.get(`/accounts/${token}/tags.json`).then((response) => {
      this.setState({ tags: response.data });
    });
  }

  handleSave = (e, newTag) => {
    this.setState((prevState) => {
      const { tags } = prevState;
      const oldTag = _.find(tags, ["id", newTag.id]);
      oldTag.name = newTag.name;
      return { tags: tags };
    });
  };

  handleDelete = (e, deletedTag) => {
    this.setState((prevState) => {
      return {
        tags: _.filter(prevState.tags, (tag) => {
          return tag.id !== deletedTag.id;
        }),
      };
    });
  };

  handleNew = (e) => {
    this.setState({ showNewTag: true });
  };

  handleNewTagHide = (e) => {
    e.preventDefault();
    this.setState({ showNewTag: false, newTagName: "", errors: [] });
  };

  handleNewTagNameChange = (e) => {
    this.setState({ newTagName: e.target.value });
  };

  handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      this.createNewTag();
    }
  };

  createNewTag = (e) => {
    const { newTagName } = this.state;
    const data = { name: newTagName };
    this.setState({ errors: [] });

    axios
      .post(`/accounts/${this.props.organization.token}/tags`, data)
      .then((response) => {
        this.setState((prevState) => ({
          tags: [response.data, ...prevState.tags],
          showNewTag: false,
          newTagName: "",
        }));

        this.mixpanelCreateTag(newTagName);
      })
      .catch((error) => {
        const errors = error.response.data;
        this.setState({ errors: errors });
      });
  };

  mixpanelCreateTag(tagName) {
    const { token, name } = this.props.organization;
    mixpanel.track("Tag List - Tag Created", {
      "Organization Token": token,
      "Organization Name": name,
      "Tag Name": tagName,
    });
  }

  render() {
    const { tags, showNewTag, newTagName, errors } = this.state;
    const { organization, canUpdate } = this.props;
    return (
      <div className="panel">
        {canUpdate && (
          <div className="panel-header justify-end">
            <div className="button-group">
              <button className="button secondary" onClick={this.handleNew}>
                New Tag
              </button>
            </div>
          </div>
        )}
        <div className="panel-body">
          <table className="dataTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>ASIN count</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {tags && tags.length > 0 ? (
                tags.map((tag) => (
                  <TagRow
                    key={tag.id}
                    tag={tag}
                    organization={organization}
                    canUpdate={canUpdate}
                    handleSave={this.handleSave}
                    handleDelete={this.handleDelete}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-text/50">
                    No tags yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Modal show={showNewTag} onHide={this.handleNewTagHide} backdrop="static">
          <Modal.Header closeButton>New Tag</Modal.Header>
          <Modal.Body>
            <div className={`form-control ${errors.length > 0 ? "invalid" : ""}`}>
              <input
                type="text"
                placeholder="New tag name"
                autoFocus
                value={newTagName}
                onChange={this.handleNewTagNameChange}
                onKeyDown={this.handleInputKeyDown}
              />
            </div>
            {errors.length > 0 && (
              <div className="mt-1 text-sm font-semibold text-status-critical">
                {errors.map((message) => (
                  <div key={message}>{message}</div>
                ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <button className="button secondary bordered" onClick={this.handleNewTagHide}>
                Cancel
              </button>
              <button className="button" onClick={this.createNewTag}>
                Create
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

class TagRow extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      editable: false,
      name: "",
      errors: null,
      deleted: false,
    };
  }

  saveTag(e) {
    const { organization, tag, handleSave } = this.props;
    const { name } = this.state;
    const data = _.assign({}, tag, { name: name });
    axios
      .put(`/accounts/${organization.token}/tags/${tag.token}`, data)
      .then((response) => {
        this.setState({
          editable: false,
          name: "",
          errors: null,
        });
        handleSave(e, response.data);

        this.mixpanelTagNameUpdated(tag, name);
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
      });
  }

  handleEdit = (e) => {
    e.preventDefault();
    this.setState({
      editable: true,
      name: this.props.tag.name,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({
      editable: false,
      name: "",
      errors: null,
    });
  };

  handleInputKeyUp = (e) => {
    if (e.key === "Enter") {
      this.saveTag(e);
    }
    // Clear error message when user is retyping new name.
    if (this.state.errors) {
      this.setState({ errors: null });
    }
  };

  handleSave = (e) => {
    e.preventDefault();
    this.saveTag(e);
  };

  handleDelete = (e) => {
    const { organization, tag, handleDelete } = this.props;
    e.preventDefault();
    axios.delete(`/accounts/${organization.token}/tags/${tag.token}`).then((response) => {
      this.setState({
        deleted: true,
      });
      // Show deleted message for 1 minute before being cleared out
      setTimeout(() => handleDelete(e, response.data), 1000);
    });

    this.mixpanelTagDeleted(tag);
  };

  mixpanelTagDeleted(tag) {
    const { token, name } = this.props.organization;
    mixpanel.track("Tag List - Tag Deleted", {
      "Organization Token": token,
      "Organization Name": name,
      Tag: tag,
    });
  }

  mixpanelTagNameUpdated(tag, newName) {
    const { token, name } = this.props.organization;
    mixpanel.track("Tags List - Tag Updated", {
      "Organization Token": token,
      "Organization Name": name,
      Tag: tag,
      "Tag New Name": newName,
    });
  }

  render() {
    const { tag, organization, canUpdate } = this.props;
    const { editable, errors, deleted } = this.state;

    return (
      <tr>
        <td className="align-middle">
          {editable ? (
            <div className="flex items-start justify-between gap-4">
              <div className={`form-control flex-1${errors ? " invalid" : ""}`}>
                <input
                  type="text"
                  className="py-1 px-2"
                  placeholder={tag.name}
                  autoFocus
                  value={this.state.name}
                  onKeyUp={this.handleInputKeyUp}
                  onChange={this.handleInputChange}
                />
                {errors && <span className="text-xs text-status-critical font-semibold">{errors.join(", ")}</span>}
              </div>
              <div className="button-group right gap-2">
                <button className="button small secondary" onClick={this.handleSave}>
                  Save
                </button>
                <button className="button small secondary bordered compact" onClick={this.handleClose} title="Cancel">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6" stroke="currentColor" />
                    <path d="M6 6L18 18" stroke="currentColor" />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-between gap-4">
              <div className="tags-container">
                <span className="tag">{tag.name}</span>
                {deleted && <span className="px-2 py-1 text-sm text-status-critical font-semibold">Deleted!</span>}
              </div>
              {canUpdate && (
                <div className="button-group right">
                  <button className="button small secondary bordered compact" onClick={this.handleEdit} title="Edit">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4.5H4.5V19.5H19.5V12" stroke="currentColor" />
                      <path d="M17.4853 6.53552L13.2426 10.7782" stroke="currentColor" />
                      <path
                        d="M21.0209 5.12132L18.8996 3L17.4854 4.41421L19.6067 6.53553L21.0209 5.12132Z"
                        stroke="currentColor"
                        strokeLinecap="square"
                      />
                      <path
                        d="M10.0607 13.9601L10.4142 11.4853L17.4853 4.41418L19.6066 6.5355L12.5355 13.6066L10.0607 13.9601Z"
                        stroke="currentColor"
                        strokeLinecap="square"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          )}
        </td>
        <td className="w-32 align-middle">{tag.asin_count}</td>
        <td className="w-64 align-middle">
          <div className="button-group right gap-2">
            <a className="button small" href={`/accounts/${organization.token}/tags/${tag.token}`}>
              Manage ASINs
            </a>
            {canUpdate && (
              <>
                <button
                  className="button small secondary bordered compact"
                  onClick={(e) => {
                    if (window.confirm(`Are you sure you wish to delete the tag '${tag.name}'? This cannot be undone.`))
                      this.handleDelete(e);
                  }}
                  title="Delete"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 8H20L18 20H6L4 8Z" stroke="currentColor" />
                    <path d="M14 5V3H10V5" stroke="currentColor" />
                    <path d="M4 5H20" stroke="currentColor" />
                    <path d="M12 10V18" stroke="currentColor" />
                    <path d="M15 10V18" stroke="currentColor" />
                    <path d="M9 10V18" stroke="currentColor" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

export default TagsIndex;
